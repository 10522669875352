.carousel {
  opacity: 0;
  transition: opacity 0.4s;
  &.is-hidden {
    display: none;
  }
  &.flickity-enabled {
    opacity: 1;
  }
}

.flickity-viewport {
  position: relative;
  width: 100%;
  min-height: 340px;
  padding-bottom: 74.4%;
}

.carousel-cell {
  width: 100%;
  min-height: 340px;
  text-align: center;
  margin: 0;
  height: 100%;
  padding-bottom: 74.4%;
}

#home-carousel {
  position: relative;
  z-index: 0;
  width: 100%;
  padding: 0;
}

.property-carousel {
  position: relative;
  z-index: 0;
  width: 100%;
  padding: 0;
  .flickity-viewport {
    min-height: 235px;
    padding-bottom: 65%;
  }

  .carousel-cell {
    min-height: 235px;
    padding-bottom: 65%;
  }
}

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: .7em;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
  .dot {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 2px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    border: solid 2px #fff;
    opacity: 1;
    &.is-selected {
      background: $orange;
    }
  }
}

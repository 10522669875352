/**
 * @license
 * MyFonts Webfont Build ID 2970555, 2015-02-11T12:28:52-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: AvenirLTStd-Medium by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/65-medium/
 * Copyright: Copyright &#x00A9; 1981 - 2006 Linotype GmbH, www.linotype.com. All rights reserved. Copyright &#x00A9; 1989 - 2002 Adobe Systems Incorporated.  All Rights Reserved.
 * Licensed pageviews: 500,000
 *
 * Webfont: AvenirLTStd-Black by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/95-black/
 * Copyright: Copyright &#x00A9; 1989, 1995, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1995, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 * Licensed pageviews: 250,000
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=2970555
 *
 * © 2015 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/2d53bb");

@font-face {
  font-family: 'AvenirLTStd-Medium';
  src: url('../fonts/2D53BB_0_0.eot');
  src: url('../fonts/2D53BB_0_0.eot?#iefix') format('embedded-opentype'), url('../fonts/2D53BB_0_0.woff2') format('woff2'), url('../fonts/2D53BB_0_0.woff') format('woff'), url('../fonts/2D53BB_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'AvenirLTStd-Black';
  src: url('../fonts/2D53BB_1_0.eot');
  src: url('../fonts/2D53BB_1_0.eot?#iefix') format('embedded-opentype'), url('../fonts/2D53BB_1_0.woff2') format('woff2'), url('../fonts/2D53BB_1_0.woff') format('woff'), url('../fonts/2D53BB_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('../fonts/glyphicons-halflings-regular.eot');
  src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype');
}

.btn-lg {
  @include button-variant(#fff, #F8981D, #F8981D);
  @include border-radius(0);
  margin-bottom: 20px;
}

.expand-button {
  background: $orange;
  border: 0;
  width: 20px;
  height: 20px;
  color: #fff;
  vertical-align: text-bottom;
  text-align: center;
  -webkit-appearance: none !important;
  position: relative;
  &:hover, &:focus {
    color: #fff;
  }
  .property & {
    margin: 10px auto;
    font-size: 12px;
    display: block;
    .glyphicon:before {
      padding: 3px 0 0 0;
    }
  }
  .person & {
    margin-top: 10px;
    margin-bottom: -10px;
    font-size: 14px;
    display: inline-block;
    @include media-breakpoint-up(md) {
      margin: 0 20px;
      .glyphicon {
        display: block;
        &:before {
          padding: 3px 0 0 2px;
        }
      }
    }
  }
  .glyphicon:before {
    padding: 2px 0 0 0;
  }
}

.expand-button.collapsed {
  .glyphicon:before {
    padding: 2px 0 0 1px;
  }
  .property & {
    .glyphicon:before {
      padding: 3px 0 0 2px;
    }
  }
  .person & {
    @include media-breakpoint-up(md) {
      .glyphicon {
        display: block;
        &:before {
          padding: 2px 0 0 3px;
        }
      }
    }
  }
}

.glyphicon {
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  &:before {
    display: block;
  }
}

.glyphicon-plus:before {
  content: "\2b";
}

.expand-button .glyphicon-plus:before {
  content: "\2212";
}

.expand-button.collapsed .glyphicon-plus:before {
  content: "\2b";
}

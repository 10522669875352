// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #f8981d;
$orange:                #f8981d;
$blue:                  #003468;
$gray-light:            #f5f5f5;

// Options

$enable-rounded:        false;

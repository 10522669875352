// Mixins

@mixin transition-all {
  transition: all 0.35s;
}

// Global

body {
  font-family: 'AvenirLTStd-Medium', sans-serif;
  font-size: 14px;
  color: $blue;
  position: relative;
  webkit-tap-highlight-color: $brand-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $blue;
  @include transition-all;
  &:hover, &:focus {
    text-decoration: none;
    color: $orange;
  }
}

h1, h2, h3 {
  color: $orange;
  text-align: center;
}

h1, h2 {
  font-family: 'AvenirLTStd-Black', sans-serif;
}

h1 {
  font-size: 2.4em;
  letter-spacing: 1px;
  line-height: 1.15;
  margin-bottom: 9%;
  margin-top: 0;
  @include media-breakpoint-up(sm) {
    font-size: 3.4em;
    text-align: left;
    padding-left: 17.1%;
  }
}

h2 {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.5em;
  letter-spacing: 1px;
  margin-top: 0.3em;
  margin-bottom: 4.2%;
}

h3 {
  font-size: 1.1em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

h4 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.1em;
  margin-bottom: 0.2em;
  margin-top: .6em;
  text-align: center;
}

p {
  line-height: 1.88;
  font-size: .96em;
  margin-bottom: 6%;
}

.orange {
  color: $orange;
}

.container-fluid {
  max-width: 1500px;
  min-width: 278px;
}

section {
  padding-top: 10.1%;
}

.after-image {
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
  img {
    @include img-fluid;
    width: 100%;
  }
}

.column-text {
  margin: 0 auto;
  width: 93%;
  @include media-breakpoint-up(md) {
    width: 83%;
  }
  p {
    font-size: .92em;
    @include media-breakpoint-up(md) {
      columns: 2;
      column-gap: 3em;
    }
  }
}

// Extras

// -- Highlight Color Customization
::selection {
  color: white;
  text-shadow: none;
  background: $brand-primary;
}

// Remove focus outlines (not recommended but requested)
:focus {
  outline: none !important;
}

::-moz-focus-inner {
  border: 0 !important;
}

//SVG
.svg {
  min-height: 1%;
  @include img-fluid;
  width: 100%;
}

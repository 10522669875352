#overview {
  p {
    @include media-breakpoint-up(md) {
      padding-left: 0.7em;
      padding-right: 14%;
    }
  }

  .after-image {
    margin-top: 8.3%;
  }
}

#services {
  h3 {
    text-align: left;
    margin-left: 20px;
    @include media-breakpoint-up(md) {
      margin-left: 12.7%;
    }
  }

  ul.list {
    padding-right: 6%;
    @include media-breakpoint-up(md) {
      margin-left: 9.2%;
    }
  }

  ul.expertise {
    padding-right: 6%;
    padding-left: 40px;
    font-family: 'Libre Baskerville', serif;
    font-style: italic;
  }

  .after-image {
    margin-top: 10%;
  }
}

#team {
  .after-image {
    margin-top: 8.6%;
  }
}

.person {
  background-color: $gray-light;
  width: 93%;
  margin: 0 auto 5%;
  padding: 5% 5% 2%;

  h1 {
    font-size: 1.8em;
    margin-bottom: .2em;
    margin-top: 0;
    padding-left: 0;
    text-align: center;
    @include media-breakpoint-up(md) {
      font-size: 3.1em;
      text-align: left;
    }
  }

  h2 {
    text-transform: inherit;
    font-family: 'AvenirLTStd-Medium', sans-serif;
    font-weight: 600;
    text-align: left;
  }

  h3 {
    text-transform: uppercase;
    margin-bottom: 0.6em;
    font-weight: 600;
    margin-top: 0;
    text-align: left;
  }

  ul {
    list-style: none;
    font-family: 'Libre Baskerville', serif;
    font-style: italic;
    padding-left: 0;
    margin-bottom: 2em;
  }

  li {
    line-height: 1.7;
  }

  &.secondary {
    margin-bottom: 10%;
    padding: 5%;

    h2 {
      text-align: center;
      margin-bottom: 1.8%;
      font-size: 1em;
      @include media-breakpoint-up(md) {
        text-align: left;
        margin-bottom: 6.8%;
      }

      .name {
        font-size: 1.4em;
      }
    }

    a {
      font-size: .96em;
    }

    p {
      text-align: center;
      color: $orange;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }

    [class^='col-'] {
      margin-bottom: 7.8%;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    [class^='col-'] + [class^='col-'] {
      margin-bottom: 0;
    }
  }
}

.person-heading {
  text-align: center;
  padding-bottom: 2.3em;
  z-index: 10;
  @include media-breakpoint-up(md) {
    text-align: left;
  }

  a {
    display: block;
    text-align: center;
    font-size: 1.5em;
    @include media-breakpoint-up(md) {
      display: inline-block;
      text-align: left;
    }
  }

  .anchor {
    font-size: 1.5em;
    display: none;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
}

.resume {
  padding-left: 7.5%;
}

#portfolio {
  .after-image {
    margin-top: 3%;
  }

  table {
    width: 93%;
    margin: 0 auto;

    tr {
      height: 50px;

      &.fill {
        background-color: #f5f5f5;
      }
    }

    th {
      font-size: 1.1em;
      height: 90px;
    }

    .first {
      padding-left: 5.7%;
      width: 38%;
    }

    .middle {
      text-align: center;
    }

    .last {
      padding-right: 5.7%;
      text-align: right;
    }
  }
}

.property {
  width: 92%;
  margin: 0 auto 12%;

  h4 + p {
    margin-bottom: 0;
  }

  p {
    text-align: center;
    margin-bottom: 1.4em;
    line-height: 1.7;
  }

  p.description {
    text-align: left;
    margin-bottom: 0;
  }

  @include media-breakpoint-up(md) {
    h4, p {
      text-align: left;
    }
  }
}

#contact {
  padding-top: 0;
}

.address-block {
  padding-left: 15px;
  text-align: center;
  margin-top: 10%;
  @include media-breakpoint-up(md) {
    padding-left: 9%;
  }
}

.information-block {
  h2 {
    font-size: 3.4em;
    margin-bottom: 30px;
    text-align: center;
    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  p {
    color: $orange;
    line-height: 1.75;
    margin-bottom: 24px;
    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  a {
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}

#map-container {
  margin: 0 -30px;
  position: relative;
  z-index: 1;
  margin-left: -15px;
  margin-right: -15px;

  @include media-breakpoint-up(md) {
    margin-right: 10%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    float: right;
  }
}

#map {
  bottom: 0;
  width: 100%;
  height: 412px;

  img {
    max-width: inherit !important;
  }

  @include media-breakpoint-up(md) {
    width: 412px;
  }
}

.container-fluid > .navbar-header {
  margin-left: 0;
}

.navbar-light {
  margin-bottom: 0;
  width: 100%;
  font-size: .8rem;
  letter-spacing: 1px;
  padding: 0;
  @include media-breakpoint-up(md) {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 84px;
      width: 100%;
      z-index: -1;
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.7)), color-stop(100%, rgba(255, 255, 255, 0.7)));
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
      background: -o-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
      background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3ffffff', endColorstr='#b3ffffff', GradientType=0);
    }
  }
  .navbar-toggler {
    border-width: 0;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.7)), color-stop(100%, rgba(255, 255, 255, 0.7)));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3ffffff', endColorstr='#b3ffffff', GradientType=0);
    margin-top: 15px;
    margin-right: 12px;
    width: 38px;
    height: 39px;
    padding: 0;
  }
  .navbar-toggler-icon {
    background: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#003468' stroke-width='3' stroke-linecap='butt' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  .navbar-brand {
    background: $orange;
    width: 197px;
    height: 111px;
    margin-left: 70px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
    margin-right: 0;
    @include media-breakpoint-up(md) {
      width: 250px;
      height: 141px;
      margin-left: 15px;
      padding-right: 33px;
    }
    @include media-breakpoint-up(lg) {
      margin-left: 8.7%;
    }
    &:focus, &:hover {
      background-color: $orange;
    }
    img {
      width: 86%;
      height: auto;
      margin-top: 26px;
      margin-left: 13px;
      @include media-breakpoint-up(md) {
        margin-left: 15px;
        margin-top: 36px;
        width: 187px;
        height: 107px;
      }
    }
  }
  .navbar-collapse {
    @include media-breakpoint-up(md) {
      align-self: flex-start;
    }
    .navbar-nav {
      top: -45px;
      position: relative;
      overflow: visible;
      margin-left: 15px;
      margin-right: 15px;
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.7)), color-stop(100%, rgba(255, 255, 255, 0.7)));
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
      background: -o-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
      background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3ffffff', endColorstr='#b3ffffff', GradientType=0);
      margin-top: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      @include media-breakpoint-up(md) {
        background: transparent;
        top: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-left: auto;
        margin-right: 6.9%;
      }
      @include media-breakpoint-up(lg) {
        margin-right: 10%;
      }
      > li {
        text-align: center;
        &:hover {
          background: #fff;
        }
        @include media-breakpoint-up(md) {
          &:hover {
            background: transparent;
          }
        }
        > a {
          line-height: 34px;
          color: $blue;
          text-transform: uppercase;
          font-family: 'AvenirLTStd-Medium', sans-serif;
          font-weight: 600;
          padding-top: 0;
          padding-bottom: 0;
          &:hover, &.active {
            color: $orange;
          }
          @include media-breakpoint-up(md) {
            line-height: 84px;
            padding-left: 15px;
            padding-right: 15px;
            display: block;
          }
        }
      }
    }
  }
}

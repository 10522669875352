.content-info {
  position: relative;
  background-color: $orange;
  height: 65px;
  z-index: -1;
  @include media-breakpoint-up(md) {
    height: 50px;
  }
  p {
    padding: 21px 0 0 0;
    right: 5%;
    position: absolute;
    margin-bottom: 0;
    color: #fff;
    @include media-breakpoint-up(md) {
      padding: 15px 0 0 100px;
      position: relative;
    }
  }
  img {
    float: left;
    margin-top: 13px;
    margin-left: 5%;
  }
}
